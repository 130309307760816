import { CoreContext } from "context/CoreContext";
import useI18n from "hooks/useI18n";
import { useContext, useState } from "react";
import { ButtonClose, PaymentsContainer, PaymentItem, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay, Subtitle } from './styled'
import { Icon, Load, Title } from 'ui/styled'
import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleCheckout, handlePixCheckout } from "utils/functions";

export default function Payments({ data }) {
  const { setModal, setCheckout, setPixCheckout } = useContext(CoreContext)
  const [loading, setLoading] = useState(false)

  const history = useHistory();
  const navigate = to => history.push(to);

  const { t } = useI18n(false)

  const card = async () => {
    await handleCheckout(data, setModal, setCheckout, navigate, loading, setLoading, data.payload)
  }

  const pix = async () => {
    
    if (data.lp) {
      setModal(null);
      setPixCheckout({...data.payload, "status": "pending"});
      navigate('login');
      return;
    }
    
    setLoading(true)
    await handlePixCheckout(data.payload, setPixCheckout, navigate)
    setLoading(false)
    setModal(null);
  }

  const handleClose = () => {
    setModal(null)
  }


  return (
    <>
      <Overlay onClick={handleClose} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { t("plans_form_payments") }
              {/* <Subtitle>
                ({ t("dashboard_subtitle_payments") })
              </Subtitle> */}
            </Title>
            <ButtonClose>
              <Icon icon='close-black' pointer onClick={() => setModal(null)} />
            </ButtonClose>
          </ModalTitleContainer>
          <ModalBody>
            <PaymentsContainer>
              {loading ? <Load newColor /> : 
                <><PaymentItem onClick={() => pix()}>
                  <PixIcon fontSize="large" />
                  <Subtitle>
                    PIX
                  </Subtitle>
                </PaymentItem><PaymentItem onClick={() => card()}>
                    <CreditCardIcon fontSize="large" />
                    <Subtitle>
                      {t("plans_payment_card")}
                    </Subtitle>
                  </PaymentItem></>
              }
            </PaymentsContainer>
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </>
  )
}