import styledCmp from 'styled-components'

import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input'; // standard (material)

export const MaterialInput = styled(Input)(({ theme, transparent, textarea }) => ({
  background: transparent ? 'transparent' : theme.palette.colors.backgroundgrey,
  fontFamily: "Poppins",
  borderRadius: 8,
  width: '100%',
  resize: 'none',
  border: 'none',
  padding: '8px 8px',
  height: textarea ? '150px' : '',
  '&:focus': {
    outline: 'none'
  }
}));

export const InputIcon = styledCmp.img.attrs({
})`
`;

export const InputContainer = styledCmp.div.attrs({
})`
  width:100%;
`;