import React, { useContext, useState } from 'react'
import { ButtonClose, ButtonContainer, FormSpacer, ModalBody, ModalContainer, ModalContent, ModalLabel, ModalTitleContainer, Overlay, Subtitle } from './styled'
import { Icon, Title } from 'ui/styled'
import PlansCard from 'components/PlansCard'
import { CoreContext } from 'context/CoreContext'
import CheckItems from 'components/CheckItems'
import Button from 'components/Form/Button'
import { UpdateUser } from 'services/mobdict'
import { optionsLevel } from 'utils/options'
import { exposeMobdictError } from 'utils'
import { toast } from 'react-toastify'
import useI18n from 'hooks/useI18n'

export default function Subgenres() {

  const { setModal, modal, user, fetchUser, language } = useContext(CoreContext)
  const [loading, setLoading] = useState(false)
  
  const [checked, setChecked] = useState([ ...modal?.selected ])

  const { t } = useI18n(false)
 
  const save = async () => {
    if( !(checked?.includes(11) || checked?.length >= 3 ) ){
      toast.error(t("register_validation_subgender"))
      return ;
    }

    if(!loading){
      setLoading(true)
        const result = await UpdateUser({
          nome: user?.nome,
          username: user?.username,
          email: user?.email,
          tel: user?.tel,
          tel_pais: user?.tel_pais,

          idioma_nativo: user?.id_idioma_nativo,
          idioma_default: user?.id_idioma,
          level: optionsLevel.find(f => f.title === user?.level)?.id,
          subgeneros: checked,
          mfa: user?.mfa,
          idioma: language
        }, user?.id)
 
        if( !exposeMobdictError(result, Object.keys(result.data).length !== 0 )){
            await fetchUser(user?.id)
            toast.success(result?.message)
            setModal(null)
        }
      setLoading(false)
    }
  }

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { t("dashboard_form_subgender") }
              <Subtitle>
                ({ t("dashboard_subtitle_subgender") })
              </Subtitle>
            </Title>
            <ButtonClose>
              <Icon icon='close-black' pointer onClick={() => setModal(null)} />
            </ButtonClose>
          </ModalTitleContainer>
          <ModalBody>
            <CheckItems options={modal?.items||[]} checked={checked} setChecked={setChecked} />
            <ButtonContainer>
              {
                loading ? null :
                  <Button secondary width="144px" outline colorBlack loading={loading}
                    onClick={() => setModal(null)}
                  >{ t("dashboard_form_cancel") }</Button>
              }

              <Button secondary width="144px" loading={loading}
                onClick={save}
              >{ t("dashboard_form_save") }</Button>
            </ButtonContainer>
          </ModalBody>

        </ModalContent>
      </ModalContainer>
    </>
  )
}
