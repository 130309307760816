import styled from 'styled-components'

export const HeaderContainer = styled.div.attrs({
})`          
    background-color: ${props => props.theme.palette.colors.white};
    min-height: 88px;
    width: 100%; 
    padding: 0 96px;
    box-shadow: 0px 3px 6px ${props => props.theme.palette.colors.shadow};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width: 991px){
        padding: 8px 20px;
    }
    @media(max-width: 500px) {
      justify-content: center;
    }
`;

export const Logo = styled.img.attrs({
    src: '/icons/logo.svg',
    alt: 'Mobdict Logo'
})`
    cursor: pointer;
    @media(max-width: 767px){
        margin-bottom: 8px;
    }
`;

export const IconContent = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 8px;

`;

export const HeaderContent = styled.div.attrs({
})`          
   
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    @media(max-width: 767px){
        gap: 8px;
    }
    @media(max-width: 500px) {
      justify-content: center;
    }
`;