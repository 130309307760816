import styled from "styled-components";

export const CheckItemsContent = styled.div.attrs({
})`
    display: ${props => props.column ? `flex` : `grid`};
    flex-direction: ${props => props.column ? `column` : `row`};
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    @media(max-width: 410px){
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const CheckItem = styled.div.attrs({
})`

`;