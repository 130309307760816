import React, { useContext, useState } from 'react'
import { ButtonClose, ButtonContainer, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay, ProfileFormContent, Text } from './styled'
import { Icon, Title } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import Button from 'components/Form/Button'
import useI18n from 'hooks/useI18n'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Input from 'components/Form/Input'
import { toast } from 'react-toastify'

export default function Warning() {

  const { setModal, modal, user } = useContext(CoreContext)
  const history = useHistory()
  const navigate = to => history.push(`/${to}`);

  const { t } = useI18n(false)
  const [codProfessor, setCodProfessor] = useState(user?.codigo_professor||null)
  const [loading, setLoading] = useState(false)
 
  const save = async () => {
    if (modal?.teacher) {
      setLoading(true)
      if (user?.codigo_professor === codProfessor) {
        toast.error(t("settings_teacher_warning_error"))
        return;
      }
      await modal?.save(codProfessor)
      setLoading(false)
      return;
    } else { 
      setModal(null)
      navigate('register-teacher')
    }
  }

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { modal?.teacher ? t("settings_teacher_warning_title") : t("dashboard_become_teacher") }
            </Title>
            <ButtonClose>
              <Icon icon='close-black' pointer onClick={() => setModal(null)} />
            </ButtonClose>
          </ModalTitleContainer>
          <ModalBody>
            {modal?.teacher ? 
              <><Text>{t("settings_teacher_warning")}</Text><ProfileFormContent>
                <Input placeholder={t("dashboard_form_teacher")} id={'cod_professor'} value={codProfessor} onChange={(e) => {
                  setCodProfessor(e.target.value);
                }} />
              </ProfileFormContent></> : 
              <Text color="red">
                  { t("teacher_register_info_2") }
              </Text>
            }
            <ButtonContainer>
              <Button secondary width="144px" outline colorBlack
                onClick={() => setModal(null)}
              >{ t("dashboard_form_cancel") }</Button>

              <Button secondary width="144px" loading={loading}
                onClick={save}
              >{ t("dashboard_form_next") }</Button>
            </ButtonContainer>
          </ModalBody>

        </ModalContent>
      </ModalContainer>
    </>
  )
}
