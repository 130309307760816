import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BootstrapTooltip } from "./styled";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const HelpIcon = ({ helpIcon, fontSize, style, icon }) => {
    return (
        <BootstrapTooltip title={helpIcon} placement="right">
          {icon === "exclamation" ? 
            (<ErrorOutlineIcon fontSize={fontSize} style={style}/>) :
            (<HelpOutlineIcon fontSize={fontSize} style={style}/>)
          }
        </BootstrapTooltip>
    );
}