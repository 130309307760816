
import { styled } from '@mui/material/styles';
import { lazy } from 'react';

const Tooltip = lazy(() => import('@mui/material/Tooltip'));
const tooltipClasses = lazy(() => import('@mui/material/Tooltip'));

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} 
    classes={{ popper: className }} 
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -7],
            },
          },
        ],
      },
    }}
    arrow/>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    backgroundColor: theme.palette.common.black,
    fontSize: 11,
    borderRadius: 8,
  },
}));