import React, { lazy, Suspense } from "react";
import Banner from "components/Landpage/Banner";
import ContainerLandpage from "containers/Landpage";
import LanguagesFlag from "components/Landpage/LanguagesFlag";
import useI18n from "hooks/useI18n";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HowWorks = lazy(() => import('components/Landpage/HowWorks'));
const MoreInfos = lazy(() => import('components/Landpage/MoreInfos'));
const CommonQuestions = lazy(() => import('components/CommonQuestions'));

export default function Landpage() {
  
    //const { setModal } = useContext(CoreContext);
    const { t } = useI18n(false)

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const howWorksData = [
        {
            banner: '/images/banner3.avif',
            title: t("landpage_learn_title"),
            text: t("landpage_learn_text"),
            label: t("landpage_learn_action"),
            light: true,
            reverse: true,
            smallContainer: true,
            //click: () => setModal({ type: 'plansLp' })
            click: () => navigate('plans')
        },
        {
            banner: '/images/banner4.avif',
            title: t("landpage_write_title"),
            text: t("landpage_write_text"),
            download: true,
            big: true,
            smallContainer: true,
            click: () => navigate('register')
        },
        {
            banner: '/images/banner5.avif',
            title: t("landpage_free_title"),
            text: t("landpage_free_text"),
            label: t("landpage_free_action"),
            light: true,
            reverse: true,
            smallContainer: true,
            click: () => navigate('register')
        }
    ];

    const howWorksDataOne = [
        {
            banner: '/images/banner2.avif',
            title: t("landpage_how_works_title"),
            text: t("landpage_how_works_text"),
            label: t("landpage_how_works_action"),
            light: false,
            //click: () => setModal({ type: 'plansLp' })
            click: () => navigate('plans')
        },
    ]


    return (
        <ContainerLandpage>
            <Banner />
            <LanguagesFlag />
            <Suspense fallback={<div>Loading...</div>}>
            {howWorksDataOne?.map((data, index) => (
                <HowWorks
                    key={index}
                    banner={data.banner}
                    title={data.title}
                    text={data.text}
                    label={data.label}
                    light={data.light}
                    reverse={data.reverse}
                    download={data.download}
                    big={data.big}
                    small={data.small}
                    click={data.click}
                />
            ))}
            <MoreInfos />
            {howWorksData?.map((data, index) => (
                <HowWorks
                    key={index}
                    banner={data.banner}
                    title={data.title}
                    text={data.text}
                    label={data.label}
                    light={data.light}
                    reverse={data.reverse}
                    download={data.download}
                    big={data.big}
                    small={data.small}
                    bannerBig={data.bannerBig}
                    smallContainer={data.smallContainer}
                    click={data.click}
                />
            ))}
            <CommonQuestions />
            </Suspense>
        </ContainerLandpage>
    );
}