import styled from 'styled-components'

export const FooterContainer = styled.div.attrs({
})`          
    background: ${props => props.theme.palette.colors.black}; 
    padding: 32px 96px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 768px) {
        padding: 24px;
    }
`;

export const FooterLogo = styled.img.attrs({
    src: '/icons/logo-mobdict-white.svg'
})`          
    width: 81.39px;
    height: 56px;

`;

export const InfoContent = styled.div.attrs({
})`          
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
`;

export const FooterText = styled.div.attrs({
})`          
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.palette.colors.white};
    max-width: 560px;
    ${props => props.upper && `
        text-transform: uppercase;
        `}
`;

export const FooterTextContent = styled.div.attrs({
})`          
    display: flex;
    flex-direction: column;
`;
export const FooterLink = styled.span.attrs({
})`          
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.palette.colors.white}
    transition: color 0.2s;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const FooterSocialMedia = styled.div.attrs({
})`          
    display: flex;
    gap: 16px;
`;

export const FooterSocialMediaIcon = styled.img.attrs({
    loading: 'lazy'
})`          
    width: 38px;
    height: 38px;
    transition: filter 0.3s ease;
    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        filter: brightness(0.7);
    }
`;

export const FooterContent = styled.div.attrs({
})`          
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    padding-bottom: 32px;
`;

export const FooterReservedText = styled.div.attrs({
})`          
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${props => props.theme.palette.colors.white};
`;