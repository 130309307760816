import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { ThemedComponent } from 'ui/theme'
import Plans from '../Plans'
import Subgenres from '../Subgenres'
import PlansLp from '../PlansLp'
import Payments from '../Payments'
import Languages from '../Languages'
import Consent from '../Consent'
import Warning from '../Warning'

export default function ModalCore() {

    const { modal } = useContext(CoreContext)

    return (
        <>
            <ThemedComponent>
                {modal?.type === 'plansLp' ? <PlansLp /> : null}
                {modal?.type === 'plans' ? <Plans /> : null}
                {modal?.type === 'subgenres' ? <Subgenres /> : null}
                {modal?.type === 'payments' ? <Payments data={modal.data}/> : null}
                {modal?.type === 'languages' ? <Languages /> : null}
                {modal?.type === 'consent' ? <Consent /> : null}
                {modal?.type === 'warning' ? <Warning /> : null}
                {modal?.type === 'teacher' ? <Warning /> : null}
            </ThemedComponent>
        </>
    )
}