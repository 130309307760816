import React from "react";
import { useHistory } from 'react-router-dom';

import {
    FooterContainer,
    FooterContent,
    FooterLink,
    FooterLogo,
    FooterReservedText,
    FooterSocialMedia,
    FooterSocialMediaIcon,
    FooterText,
    FooterTextContent,
    InfoContent,
} from './styled';
import useI18n from "hooks/useI18n";


export default function Footer() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { t } = useI18n(false)


    const footerData = [
        {
            type: 'info',
            content: [
                'TECHNNI SOFTWARE DEVELOPMENT LTDA - CNPJ: 55.008.821/0001-66',
                '1636 Av. PAULISTA CONJ 5 PAVLH 11 SUITES 1105/230 - BELA VISTA - SAO PAULO – SP 01310-200 - BRAZIL',
            ],
            upper: true,
        },
        {
            type: 'contact',
            content: [
                { label: `${t("footer_talk_us")}: `, link: 'technni@technni.com' },
                { label: `${t("footer_support")}: `, link: 'helpme@technni.com' },
                { label: 'WhatsApp: ', link: '+55 11 91054-1902' },
            ],
        },
    ];

    const footerSocialMedia = [
        {
            label: 'Instagram',
            link: 'https://www.instagram.com/mobdict',
            icon: 'insta',
        },
        {
            label: 'Facebook',
            link: 'https://www.facebook.com/mobdict',
            icon: 'face',
        },
        {
            label: 'LinkedIn',
            link: 'https://www.linkedin.com/company/mobdict/',
            icon: 'linkedin',
        },
        {
            label: 'Youtube',
            link: 'https://www.youtube.com/channel/UCHGmjL2tHbAoYRj9GiHntAA',
            icon: 'youtube',
        }
    ];


    
    
    
    return (
        <FooterContainer>
            <FooterContent>
                <InfoContent>
                    <FooterLogo />
                    {footerData.map((section, index) => (
                        <FooterTextContent key={index}>
                            {section.content.map((item, idx) => (
                                <FooterText key={idx} upper={section.upper}>
                                    {section.type === 'info' ? item : item.label}
                                    {item.link && (
                                        <FooterLink >
                                            {item.link}
                                        </FooterLink>
                                    )}
                                </FooterText>
                            ))}
                        </FooterTextContent>
                    ))}
                </InfoContent>
                <FooterSocialMedia>
                    {footerSocialMedia.map((item, index) => (
                        <FooterSocialMediaIcon key={index} src={`/icons/${item.icon}.svg`} onClick={() => window.open(item?.link, "")} />
                    ))}
                </FooterSocialMedia>
            </FooterContent>
            <FooterReservedText>
                © Technni 2024 - { t("footer_all_rights") }
            </FooterReservedText>
        </FooterContainer>
    );
}